// spin animation
.spin-fast {
  animation: spin 0.5s infinite linear;
}
.spin-med {
  animation: spin 1s infinite linear;
}
.spin-slow {
  animation: spin 2s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

