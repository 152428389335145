@use '@angular/material' as mat;
@use 'sass:map';

@include mat.core();

@import 'base_theme.scss';

$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warning,
    ),
    typography: mat.define-typography-config(
      $font-family: 'Nunito Sans',
      $button: mat.define-typography-level(
        $font-size: 0.875rem,
        $font-weight: 600,
        $letter-spacing: 0,
      ),
      ),
    density: 0,
  )
);
$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warning,
    ),
    density: 0,
  )
);

@include mat.all-component-themes($light-theme);

$color-config: mat.get-color-config($light-theme);

:root {
  --color-primary: #{mat.get-color-from-palette(map.get($color-config, 'primary'), default)};
  --color-accent: #{mat.get-color-from-palette(map.get($color-config, 'accent'), default)};;
  --color-warn: #{mat.get-color-from-palette(map.get($color-config, 'warn'), default)};;
  --color-success: #46a35e;

  --bg-primary: #fff;
  --bg-secondary: #f5f5f5;
  --bg-tertiary: #f0f0f0;

  --text-primary: #333;
  --text-secondary: #757575;

  --border-color: #e0e0e0;

  --border-radius-sm: 0.125rem; 
  --border-radius: 0.25rem;
  --border-radius-lg: 0.5rem;
  --border-radius-xl: 1rem;
}

.dark-theme {
  @include mat.all-component-themes($dark-theme);
  $color-config: mat.get-color-config($dark-theme);

  --color-primary: #{mat.get-color-from-palette(map.get($color-config, 'primary'), default)};
  --color-accent: #{mat.get-color-from-palette(map.get($color-config, 'accent'), default)};
  --color-warn: #{mat.get-color-from-palette(map.get($color-config, 'warn'), default)};
  --color-success: #46a35e;

  --bg-primary: #333;
  --bg-secondary: #393939;
  --bg-tertiary: #494949;

  --text-primary: #fff;
  --text-secondary: #ccc;

  --border-color: #444;
}
