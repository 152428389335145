@import "./app/styles/animations.scss";
@import "theme";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Nunito Sans', "Helvetica Neue", sans-serif;
  display: flex;
  flex-direction: column;
  padding: 4rem 0 0;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

* {
  box-sizing: border-box;
}

.button-group {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.spacer {
  flex: 1 1 0px;
}

.mat-mdc-snack-bar-container {
  &.error-snackbar {
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}

mat-snack-bar-container {
  padding-top: 60px;
}

.bg-primary {
  background-color: var(--bg-primary);
}
.bg-secondary {
  background-color: var(--bg-secondary);
}
.bg-tertiary {
  background-color: var(--bg-tertiary);
}
.text-primary {
  color: var(--text-primary);
}
.text-secondary {
  color: var(--text-secondary);
}
